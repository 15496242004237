"use client";

import { useLocale } from "next-intl";

import css from "@/src/components/header/menu/language/language.module.css";
import MenuLink from "@/src/components/header/menu/menu-link";
import { Locale, getAvailableLocalesToFullName } from "@/src/intl/locale";
import { usePathname } from "@/src/intl/navigation";

const LanguageList = () => {
  const currentLocale = useLocale();
  const pathname = usePathname();

  return (
    <ul className={css.languages}>
      {Object.entries(getAvailableLocalesToFullName(true, currentLocale)).map(
        ([locale, name]) => (
          <li key={locale}>
            <MenuLink
              locale={locale as Locale}
              href={pathname}
              removeHoverMarginY
            >
              {name}
            </MenuLink>
          </li>
        ),
      )}
    </ul>
  );
};

export default LanguageList;
