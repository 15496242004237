export const locales = ["en", "nn", "nb"] as const;

export const DEFAULT_LOCALE = "nb";

export type Locale = (typeof locales)[number];

export type LocaleFullName = Record<Locale, string>;

export const LocaleToFullName = {
  en: "English",
  nn: "Norsk - Nynorsk",
  nb: "Norsk - Bokmål",
} satisfies LocaleFullName;

export function getAvailableLocalesToFullName(
  unusedOnly: true,
  currentLocale: string,
): Partial<Record<Locale, string>>;
export function getAvailableLocalesToFullName(): LocaleFullName;

export function getAvailableLocalesToFullName(
  unusedOnly = false,
  currentLocale = DEFAULT_LOCALE,
) {
  if (unusedOnly) {
    return Object.entries(LocaleToFullName).reduce(
      (acc: Partial<LocaleFullName>, [locale, name]) =>
        locale === currentLocale ? acc : { ...acc, [locale]: name },
      {},
    );
  }

  return LocaleToFullName;
}
