"use client";

import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

type SetMenuIsOpen = Dispatch<SetStateAction<boolean>>;

interface MenuContextProps {
  menuIsOpen: boolean;
  setMenuIsOpen: SetMenuIsOpen;
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined);

export const useMenuContext = () => {
  const context = useContext(MenuContext);

  if (context === undefined) {
    throw new Error("useMenuContext has no corresponding provider");
  }

  return context;
};

interface MenuProviderProps {
  children: ReactNode;
}

const MenuProvider = ({ children }: MenuProviderProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const value: MenuContextProps = useMemo(
    () => ({
      menuIsOpen,
      setMenuIsOpen,
    }),
    [menuIsOpen],
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export default MenuProvider;
