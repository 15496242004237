"use client";

import clsx from "clsx";

import { ReactNode } from "react";

import { useMenuPerDeviceContext } from "./menu-per-device-provider";
import { useMenuContext } from "./menu-provider";
import css from "./menu.module.css";

interface MenuDropdownProps {
  children: ReactNode;
  className?: string;
}

const MenuDropdown = ({ children, className }: MenuDropdownProps) => {
  const { menuIsOpen } = useMenuContext();
  const { menuId, menuContentRef } = useMenuPerDeviceContext();

  return (
    <div
      className={clsx(css.menuDropdown, menuIsOpen && css.open, className)}
      aria-hidden={!menuIsOpen}
      /* @ts-expect-error: Full support will be added with React 19 https://github.com/facebook/react/issues/17157#issuecomment-2003750544 */
      inert={menuIsOpen ? undefined : ""}
      id={menuId}
      ref={menuContentRef}
      data-color-scheme="dark"
    >
      <div className={css.menu}>{children}</div>
    </div>
  );
};

export default MenuDropdown;
