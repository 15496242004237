"use client";

import {
  ReactNode,
  Ref,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";

import { useSearchParams } from "next/navigation";

import { usePathname } from "@/src/intl/navigation";

import { useMenuContext } from "./menu-provider";

interface MenuPerDeviceContextProps {
  menuId: string;
  menuContentRef: Ref<HTMLDivElement>;
}

const MenuPerDeviceContext = createContext<
  MenuPerDeviceContextProps | undefined
>(undefined);

export const useMenuPerDeviceContext = () => {
  const context = useContext(MenuPerDeviceContext);

  if (context === undefined) {
    throw new Error("useMenuPerDeviceContext has no corresponding provider");
  }

  return context;
};

const usePathnameSearchParams = (): string =>
  `${usePathname()}?${useSearchParams().toString()}`;

const useCloseMenuListeners = (element: HTMLElement | null) => {
  const { setMenuIsOpen } = useMenuContext();
  const pathnameSearchParams = usePathnameSearchParams();

  const closeMenu = useCallback(() => {
    setMenuIsOpen(false);
  }, [setMenuIsOpen]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        closeMenu();
      }
    },
    [closeMenu],
  );

  useEffect(() => {
    if (element) {
      window.addEventListener("keydown", handleKeyDown);
    }

    closeMenu();

    return () => {
      if (element) {
        window.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [closeMenu, element, handleKeyDown, pathnameSearchParams]);
};

export const TRANSITION_DURATION = 0.25;

/* When editing this value, also remember to update --max-content-multiplier in menu.module.css */
const MAX_CONTENT_MULTIPLIER = 5;

const useSetOpenCloseTransitionDuration = (element: HTMLElement | null) => {
  const { menuIsOpen } = useMenuContext();

  useEffect(() => {
    const prefersReducedMotion = window.matchMedia(
      "(prefers-reduced-motion: reduce)",
    ).matches;

    if (element && !prefersReducedMotion) {
      element.style.transitionDuration = `${menuIsOpen ? TRANSITION_DURATION * MAX_CONTENT_MULTIPLIER : TRANSITION_DURATION}s`;
    }
  }, [element, menuIsOpen]);
};

interface MenuPerDeviceProviderProps {
  children: ReactNode;
}

const MenuPerDeviceProvider = ({ children }: MenuPerDeviceProviderProps) => {
  const menuId = useId();
  const [menuContent, setMenuContent] = useState<HTMLElement | null>(null);

  const menuContentRef = useCallback((node: HTMLElement | null) => {
    if (node) {
      setMenuContent(node);
    }
  }, []);

  useCloseMenuListeners(menuContent);
  useSetOpenCloseTransitionDuration(menuContent);

  const value = useMemo<MenuPerDeviceContextProps>(
    () => ({
      menuId,
      menuContentRef,
    }),
    [menuId, menuContentRef],
  );

  return (
    <MenuPerDeviceContext.Provider value={value}>
      {children}
    </MenuPerDeviceContext.Provider>
  );
};

export default MenuPerDeviceProvider;
