import clsx from "clsx";

import { ReactNode } from "react";

import css from "./footer.module.css";

interface ContentColProps {
  children: ReactNode;
  className?: string;
}

const ContentCol = ({ children, className }: ContentColProps) => (
  <div className={clsx(css.col, className)}>{children}</div>
);

export default ContentCol;
