import clsx from "clsx";

import { ReactNode } from "react";

import { LinkProps } from "next/link";

import { Locale } from "@/src/intl/locale";
import { Link } from "@/src/intl/navigation";

import css from "./menu.module.css";

interface MenuLinkProps extends LinkProps {
  removeHoverMarginY?: boolean;
  className?: string;
  locale?: Locale;
  children: ReactNode;
}

const MenuLink = ({
  className,
  removeHoverMarginY,
  ...props
}: MenuLinkProps) => (
  <Link
    {...props}
    className={clsx(
      css.menuLink,
      removeHoverMarginY && css.removeHoverMarginY,
      "sds-typography-link",
      className,
    )}
  />
);

export default MenuLink;
