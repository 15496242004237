import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/node_modules/@unleash/nextjs/dist/FlagProvider.js");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/node_modules/@unleash/nextjs/dist/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/public/assets/opptak.svg");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/public/assets/sikt-logo-symbol.svg");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/builds/opptak/min-utdanning/src/app/_lib/apollo-wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/app/[locale]/layout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/src/components/footer/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/src/components/header/header-content-button.tsx");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/header/header.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/src/components/header/menu/collapsable-section.tsx");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/header/menu/content-and-profile/content-and-profile.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/src/components/header/menu/language/language-list.tsx");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/header/menu/language/language.module.css");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/header/menu/login-logout/login-logout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/src/components/header/menu/menu-dropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/src/components/header/menu/menu-per-device-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/src/components/header/menu/menu-provider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/components/header/menu/menu.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/src/components/stripped/stripped-header/stripped-header.tsx");
;
import(/* webpackMode: "eager" */ "/builds/opptak/min-utdanning/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/min-utdanning/src/utils/auth-context.tsx");
