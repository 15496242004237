import { useTranslations } from "next-intl";

import { Button } from "@sikt/sds-button";
import { Heading2 } from "@sikt/sds-core";

import ContentCol from "./content-col";
import css from "./footer.module.css";

const Feedback = () => {
  const t = useTranslations("footer.feedback");

  return (
    <ContentCol className={css.feedback}>
      <Heading2 variant="paragraph" className={css.heading}>
        {t("have-feedback")}
      </Heading2>
      <Button className={css.button}>{t("give-feedback")}</Button>
    </ContentCol>
  );
};

export default Feedback;
