"use client";

import { useTranslations } from "next-intl";

import { Button } from "@sikt/sds-button";

import { HeaderType } from "./header-content";
import { useMenuPerDeviceContext } from "./menu/menu-per-device-provider";
import { useMenuContext } from "./menu/menu-provider";

interface HeaderContentProps {
  type: HeaderType;
}

const HeaderContentButton = ({ type }: HeaderContentProps) => {
  const { menuIsOpen, setMenuIsOpen } = useMenuContext();
  const { menuId } = useMenuPerDeviceContext();
  const t = useTranslations("header.menu");

  return (
    <Button
      aria-controls={menuId}
      onClick={() => {
        setMenuIsOpen(!menuIsOpen);
      }}
      data-testid={`menu-button-${type}`}
    >
      {menuIsOpen ? t("close") : t("title")}
    </Button>
  );
};

export default HeaderContentButton;
