import clsx from "clsx";

import { Logo as SDSLogo } from "@sikt/sds-logo";

import { Locale } from "@/src/intl/locale";

import css from "./footer.module.css";

interface LogoProps {
  locale: Locale;
}

const Logo = ({ locale }: LogoProps) => (
  <div className={css.logo}>
    {/* SDS logo contains text content that is accessible to screen readers */}
    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
    <a
      href="https://sikt.no/"
      className={clsx("sds-typography-link", css.link)}
    >
      <SDSLogo variant="secondary" lang={locale} />
    </a>
  </div>
);

export default Logo;
