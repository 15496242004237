"use client";

import { Locale } from "@/src/intl/locale";

import Feedback from "./feedback";
import css from "./footer.module.css";
import Links from "./links";
import Logo from "./logo";

interface FooterProps {
  locale: Locale;
}

const Footer = ({ locale }: FooterProps) => (
  <footer className={css.footer} data-color-scheme="dark" data-testid="footer">
    <Logo locale={locale} />
    <Feedback />
    <Links />
  </footer>
);

export default Footer;
