"use client";

import clsx from "clsx";

import { ReactNode, useEffect, useId, useState } from "react";

import { Button } from "@sikt/sds-button";
import { Heading2 } from "@sikt/sds-core";
import { CaretDownIcon, CaretUpIcon } from "@sikt/sds-icons";

import { TRANSITION_DURATION } from "./menu-per-device-provider";
import { useMenuContext } from "./menu-provider";
import css from "./menu.module.css";

interface CollapsableSectionProps {
  heading: string;
  children: ReactNode;
  className?: string;
}

const CollapsableSection = ({
  heading,
  children,
  className,
}: CollapsableSectionProps) => {
  const id = useId();
  const { menuIsOpen } = useMenuContext();
  const [isOpen, setIsOpen] = useState(false);

  // Handles the collapse of the section after the parent menu collapse transition has finished
  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!menuIsOpen) {
      timeout = setTimeout(() => {
        setIsOpen(false);
      }, TRANSITION_DURATION * 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [menuIsOpen]);

  return (
    <section
      className={clsx(
        css.collapsableSection,
        isOpen && css.openCollapsible,
        className,
      )}
    >
      <Heading2 variant="paragraph" className={css.sectionHeading}>
        <Button
          variant="transparent"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          icon={isOpen ? <CaretUpIcon /> : <CaretDownIcon />}
          className={css.button}
          id={`${id}-title`}
          aria-expanded={isOpen}
          aria-controls={`${id}-content`}
        >
          {heading}
        </Button>
      </Heading2>
      <div
        role="region"
        id={`${id}-content`}
        aria-labelledby={`${id}-title`}
        hidden={!isOpen}
        className={css.content}
        /* @ts-expect-error: Full support will be added with React 19 https://github.com/facebook/react/issues/17157#issuecomment-2003750544 */
        inert={isOpen ? undefined : ""}
      >
        {children}
      </div>
    </section>
  );
};

export default CollapsableSection;
