import clsx from "clsx";

import { useTranslations } from "next-intl";

import { Link as NextLink } from "@/src/intl/navigation";

import ContentCol from "./content-col";
import css from "./footer.module.css";

interface Link {
  href: string;
  text: string;
}

const useLinks = (): Link[] => {
  const t = useTranslations("footer.links");
  const fallback = "/work-in-progress";

  return [
    { href: fallback, text: t("declaration") },
    { href: fallback, text: t("privacy") },
  ];
};

const Links = () => {
  const links = useLinks();

  return (
    <ContentCol>
      <ul className={clsx(css.col, css.linkList)}>
        {links.map(({ href, text }) => (
          <li key={`${href}-${text}`}>
            <NextLink href={href} className="sds-typography-link">
              {text}
            </NextLink>
          </li>
        ))}
      </ul>
    </ContentCol>
  );
};

export default Links;
