"use client";

import { useTranslations } from "next-intl";

import { ButtonLink } from "@sikt/sds-button";
import { Header } from "@sikt/sds-header";

import { Link } from "@/src/intl/navigation";

const StrippedHeader = () => {
  const t = useTranslations("header");

  return (
    <Header logoText={t("title")}>
      <Link href="/login" passHref legacyBehavior>
        <ButtonLink>{t("login")}</ButtonLink>
      </Link>
    </Header>
  );
};

export default StrippedHeader;
